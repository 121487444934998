import { createId, createUid, type Options } from '#netzo/utils/core/index'
import { format, parseISO } from 'date-fns'
import { merge } from 'lodash-es'

export const getDefaultInquiry = (data: Partial<Inquiry>) => {
  const uid = createUid(new Date(), 'SOL') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'existing',
    status: 'pending',
    uid: uid,
    name: uid,
    isDatetime: false,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    tags: [],
    files: [],
    data: getDefaultInquiryData(data?.data ?? {}),
  }, data)
}

export const getInquiryInformation = (data: Partial<InquiryWithRelations>) => {
  if (!data) return []
  const maps = {
    type: toMapByKey(optionsInquiries.type, 'value'),
    status: toMapByKey(optionsInquiries.status, 'value'),
    source: toMapByKey(optionsInquiries.source, 'value'),
  }

  const localDate = data.date ? parseISO(data.date) : null
  const date = localDate
    ? data.isDatetime
      ? localDate.toLocaleString()
      : localDate.toLocaleDateString()
    : null
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Fecha', date],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Origen', maps.source.get(data.source!)?.label],
    ['Detalles', data.text],
  ].filter(([_, value]) => value != null)
}

export const optionsInquiries = {
  type: [
    { value: 'new', label: 'Cliente Nuevo', description: 'La consulta proviene de un cliente nuevo que todavía no está registrado en nuestra base de datos.', icon: 'i-mdi-plus-circle', color: 'blue' },
    { value: 'existing', label: 'Cliente Existente', description: 'La consulta proviene de un cliente existente que ya está registrado en nuestra base de datos.', icon: 'i-mdi-office-building', color: 'green' },
  ],
  status: optionsSharedApp.statusProcess,
  source: [
    { value: 'website', label: 'Página web', description: 'Solicitud recibida a través de nuestra página web.', color: 'blue', icon: 'i-mdi-web' },
    { value: 'email', label: 'Email', description: 'Solicitud recibida por correo electrónico.', color: 'purple', icon: 'i-mdi-email' },
    { value: 'call', label: 'Llamada', description: 'Solicitud recibida mediante una llamada telefónica.', color: 'blue', icon: 'i-mdi-phone' },
    { value: 'videocall', label: 'Videollamada', description: 'Solicitud recibida a través de una videollamada.', color: 'orange', icon: 'i-mdi-video' },
    { value: 'meeting', label: 'Reunión', description: 'Solicitud recibida en una reunión programada.', color: 'purple', icon: 'i-mdi-calendar' },
    { value: 'whatsapp', label: 'WhatsApp', description: 'Solicitud recibida a través de WhatsApp.', color: 'green', icon: 'i-mdi-whatsapp' },
    { value: 'other', label: 'Otro', description: 'Solicitud recibida de otra fuente.', color: 'gray', icon: 'i-mdi-asterisk' },
  ],
} satisfies Options

export const optionsInquiriesMap = {
  Tipo: optionsInquiries.type,
  Estado: optionsDeals.status,
  Origen: optionsInquiries.source,
}

export const relationsInquiries: Record<string, boolean> = {
  account: true,
  branch: true,
  campaign: true,
  contact: true,
  deal: true,
}

export type InquiryWithRelations = Inquiry & {
  account: Account
  branch: Branch
  campaign: Campaign
  contact: Contact
  deal: Deal
}

export const inquiryToViewCalendarEvent = (calendarId: keyof Inquiry, utils: InquiriesUtils) => {
  return (row: Inquiry): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.date), dateFormat),
      end: format(new Date(row.date), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const viewSettingsInquiries: ViewSettings<Inquiry> = {
  record: 'inquiries',
  views: optionsShared.views.filter(view => ['table', 'inbox', 'calendar'].includes(view.value)),
  selectedView: 'inbox',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'source',
      label: 'Origen',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'campaignId',
      label: 'Campaña',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsInquiries.type },
    { label: 'Estado', value: 'status', options: optionsInquiries.status },
    { label: 'Origen', value: 'source', options: optionsInquiries.source },
  ],
  group: 'type',
}
