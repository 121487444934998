<script setup lang="ts">
import { format } from 'date-fns'
import type { ZodSchema } from 'zod'

const model = defineModel<Payment>({ required: true })

const props = defineProps<{
  schema: ZodSchema
  disabledFields?: (keyof Quote | string)[]
}>()

const isBaseCurrencySelected = computed(
  () => model.value.currency && model.value.currency === defaultAppVariables.currency,
)

const { state: exchangeRateCurrent, execute } = useExchangeRate()

async function refreshExchangeRate() {
  await execute()
  const exchangeRate: Payment['exchangeRate'] = {
    value: exchangeRateCurrent.value.value,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  }
  if (!isBaseCurrencySelected.value) {
    if (['USD'].includes(model.value.currency)) {
      exchangeRate.value = Math.round((1 / exchangeRateCurrent.value.value) * 1e6) / 1e6
      exchangeRate.date = new Date(exchangeRateCurrent.value.date).toISOString()
    }
  }
  if (isBaseCurrencySelected.value) {
    exchangeRate.value = 1
  }
  model.value.exchangeRate = exchangeRate
}

whenever(() => model.value.currency, refreshExchangeRate)

const getIconCurrency = (currency: Payment['currency']) => ({
  USD: 'i-mdi-currency-usd',
  MXN: 'i-mdi-currency-usd',
  EUR: 'i-mdi-currency-eur',
})?.[currency] ?? 'i-mdi-currency-usd'
</script>

<template>
  <UFormGroup
    label="Uso del CFDI"
    name="payment.satCfdiUse"
    :required="isRequired(schema, 'payment.satCfdiUse')"
  >
    <SelectMenuBase
      v-model="model.satCfdiUse"
      :options="optionsSAT.taxCfdiUse"
      :disabled="disabledFields?.includes('payment.satCfdiUse')"
    />
  </UFormGroup>

  <UFormGroup
    label="Método de pago"
    name="payment.satPaymentMethod"
    :required="isRequired(schema, 'payment.satPaymentMethod')"
  >
    <SelectMenuBase
      v-model="model.satPaymentMethod"
      :options="optionsSAT.paymentMethod"
      :disabled="disabledFields?.includes('payment.satPaymentMethod')"
    />
  </UFormGroup>

  <UFormGroup
    label="Forma de pago"
    name="payment.satPaymentForm"
    :required="isRequired(schema, 'payment.satPaymentForm')"
  >
    <SelectMenuBase
      v-model="model.satPaymentForm"
      :options="optionsSAT.paymentForm"
      :disabled="disabledFields?.includes('payment.satPaymentForm')"
    />
  </UFormGroup>

  <UFormGroup
    label="Condiciones de pago"
    name="payment.conditions"
    :required="isRequired(schema, 'payment.conditions')"
  >
    <SelectMenuBaseInfo
      v-model="model.conditions"
      :options="optionsAccounts.billing.paymentConditions"
      :disabled="disabledFields?.includes('payment.conditions')"
    />
  </UFormGroup>

  <UFormGroup
    label="Moneda"
    name="payment.currency"
    :required="isRequired(schema, 'payment.currency')"
  >
    <SelectMenuBase
      v-model="model.currency"
      :options="optionsSAT.currency"
      :disabled="disabledFields?.includes('payment.currency')"
      @click="refreshExchangeRate"
    />
  </UFormGroup>

  <UFormGroup
    v-if="!isBaseCurrencySelected"
    label="Tipo de cambio"
    name="payment.exchangeRate"
    :hint="`(D.O.F. ${new Date(model.exchangeRate?.date).toLocaleDateString()})`"
    :required="isRequired(schema, 'payment.exchangeRate')"
    :ui="{ hint: 'text-xs' }"
  >
    <UButtonGroup class="flex">
      <UInput
        v-model="model.exchangeRate.value"
        type="number"
        :trailing-icon="getIconCurrency(defaultAppVariables.currency)"
        inputmode="decimal"
        :min="0.0000000"
        :step="0.000001"
        :disabled="disabledFields?.includes('payment.exchangeRate.value')"
        class="flex-1"
      />
      <ButtonRefresh @click="refreshExchangeRate" />
    </UButtonGroup>
  </UFormGroup>
</template>
