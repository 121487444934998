<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Deal>
  onSubmit: (event: FormSubmitEvent<Partial<Deal>>) => void
  title?: string
  disabledFields?: (keyof Deal | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Deal>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useDeals()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { type: ['customer'], $columns: ['id', 'name', 'status', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: { $columns: ['id', 'name', 'image', 'status', 'phone', 'address', 'accountId'] },
  default: () => [],
})
const $campaigns = await useFetch<Campaign[]>('/api/db/campaigns', {
  query: { $columns: ['id', 'uid', 'name', 'status', 'description', 'image', 'type'] },
  default: () => [],
})
const $inquiries = await useFetch<Inquiry[]>('/api/db/inquiries', {
  query: { $columns: ['id', 'uid', 'name', 'type', 'status', 'source', 'date', 'text', 'image', 'accountId', 'campaignId'] },
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const $quotes = await useFetch<Quote[]>('/api/db/quotes', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useDealsUtils({ $accounts, $branches, $campaigns, $inquiries, $contacts, $users, $quotes })

const accounts$ = useAccounts()
const branches$ = useBranches()
const contacts$ = useContacts()
const inquiries$ = useInquiries()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.deals,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateInquiryId = (inquiryId: string) => {
  const inquiry = utils.maps.inquiryId.value.get(inquiryId)!
  state.value.accountId = inquiry?.accountId as string
  state.value.contactId = inquiry?.contactId as string
  state.value.campaignId = inquiry?.campaignId as string
  state.value.files = inquiry?.files
}

const loading = ref(false)

const information = computed(() => {
  const account = utils.maps.accountId.value.get(state.value.accountId) as Partial<Account>
  const branch = utils.maps.branchId.value.get(state.value.branchId) as Partial<Branch>
  const campaign = utils.maps.campaignId.value.get(state.value.campaignId) as Partial<Campaign>
  const contact = utils.maps.contactId.value.get(state.value.contactId) as Partial<Contact>
  const inquiry = utils.maps.inquiryId.value.get(state.value.inquiryId) as Partial<Inquiry>
  return {
    account: getAccountInformation(account),
    branch: getBranchInformation(branch),
    campaign: getCampaignInformation(campaign),
    contact: getContactInformation(contact),
    inquiry: getInquiryInformation(inquiry),
  }
})

const onSubmitDeal = async (event: FormSubmitEvent<Partial<Deal>>) => {
  loading.value = true
  if (['outbound'].includes(event.data.type)) delete event.data.inquiryId
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.deals"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitDeal"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :help="`Referencia: ${state.uid}`"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsDeals.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="['inbound'].includes(state.type)"
            label="Consultas"
            name="inquiryId"
            :required="isRequired(schema, 'inquiryId')"
          >
            <div class="w-full flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.inquiryId"
                  :options="utils.options.inquiryId.value"
                  :disabled="disabledFields?.includes('inquiryId')"
                  class="flex-1"
                  @update:model-value="onUpdateInquiryId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('inquiryId')"
                  @click="openNested(() => inquiries$.slideoverOpenCreate({
                    data: { type: 'existing' },
                    onSubmit: async (event) => {
                      const inquiry = await inquiries$.onSubmitCreate(event.data)
                      state.inquiryId = inquiry!.id
                      state.accountId = inquiry!.accountId
                      state.branchId = inquiry!.branchId
                      state.contactId = inquiry!.contactId
                      state.campaignId = inquiry!.campaignId
                      await $inquiries.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh :disabled="!state.inquiryId" @click="onUpdateInquiryId(state.inquiryId)" />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.type === 'inbound'"
            title="Información de la consulta"
            :entries="information.inquiry"
            :default-open="false"
          />

          <UFormGroup
            label="Vendedor"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Campaña"
            name="campaignId"
            :required="isRequired(schema, 'campaignId')"
          >
            <SelectMenuBase
              v-model="state.campaignId"
              :options="utils.maps.campaignByType.value.get(state.type)?.map(toOption) ?? []"
              :disabled="disabledFields?.includes('campaignId') || state.type === 'inbound'"
              class="flex-1"
            />
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la campaña"
            :entries="information.campaign"
            :default-open="false"
          />

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuNative
                v-if="state.type === 'outbound'"
                v-model="state.accountId"
                :options="utils.options.accountId.value"
                :disabled="disabledFields?.includes('accountId')"
                class="flex-1"
              />
              <InputReadOnly
                v-else
                :model-value="utils.maps.accountId.value.get(state.accountId)?.name"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('accountId') || ['inbound'].includes(state.type)"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'customer', status: 'active' },
                  disabledFields: ['type'],
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.accountId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la cuenta"
            :entries="information.account"
            :default-open="false"
          />

          <UFormGroup
            label="Sucursal"
            name="branchId"
            :required="isRequired(schema, 'branchId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.branchId"
                :options="utils.maps.branchesByAccountId.value.get(state.accountId as string)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                @click="openNested(() => branches$.slideoverOpenCreate({
                  data: { accountId: state.accountId },
                  disabledFields: ['accountId'],
                  onSubmit: async (event) => {
                    const branch = await branches$.onSubmitCreate(event.data)
                    state.branchId = branch!.id
                    await $branches.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la sucursal"
            :entries="information.branch"
            :default-open="false"
          />

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(schema, 'contactId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactId"
                :options="utils.maps.contactByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactId') || !state.accountId"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'customer', accountId: state.accountId, branchId: state.branchId },
                  disabledFields: ['type', 'accountId'],
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del contacto"
            :entries="information.contact"
            :default-open="false"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="optionsDeals.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsDeals.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="optionsDeals.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Valoración"
            name="rating"
            :required="isRequired(schema, 'rating')"
          >
            <SelectMenuBaseInfo
              v-model="state.rating"
              :options="optionsDeals.rating"
              :disabled="disabledFields?.includes('rating')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `deals/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppDealsFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.deals"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
