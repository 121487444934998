<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Activity>
  onSubmit: (event: FormSubmitEvent<Partial<Activity>>) => void
  title?: string
  disabledFields?: (keyof Activity | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Activity>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useActivities()

const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $columns: ['id', 'uid', 'name', 'image', 'type', 'stage', 'status', 'accountId', 'branchId', 'userId'] },
  default: () => [],
})
const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { type: ['customer'], $columns: ['id', 'name', 'status', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: { $columns: ['id', 'name', 'image', 'address', 'accountId'] },
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useActivitiesUtils({ $contacts, $deals, $accounts, $branches, $users })

const branches$ = useBranches()
const contacts$ = useContacts()
const deals$ = useDeals()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.activities,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateDealId = async (dealId: string) => {
  const deal = utils.maps.dealId.value.get(dealId) as Partial<Deal>
  state.value.accountId = deal.accountId!
  state.value.branchId = deal.branchId
}

const information = computed(() => {
  const account = utils.maps.accountId.value.get(state.value.accountId) as Partial<Account>
  const branch = utils.maps.branchId.value.get(state.value.branchId) as Partial<Branch>
  const deal = utils.maps.dealId.value.get(state.value.dealId) as Partial<Deal>
  return {
    account: getAccountInformation(account),
    branch: getBranchInformation(branch),
    deal: getDealInformation(deal),
  }
})

const loading = ref(false)

const onSubmitActivity = async (event: FormSubmitEvent<Partial<Activity>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.activities"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitActivity"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
            :help="`Referencia: ${state.uid}`"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsActivities.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="optionsActivities.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <div class="flex gap-1">
              <SelectMenuBaseReference
                v-model="state.dealId"
                :options="utils.options.dealId.value"
                :disabled="disabledFields?.includes('dealId')"
                @update:model-value="onUpdateDealId"
                @click:create="openNested(() => {
                  const deals$ = useRecord('deals')
                  deals$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const deal = await deals$.onSubmitCreate(event.data)
                      state.dealId = deal!.id
                      state.accountId = deal!.accountId
                      state.branchId = deal!.branchId
                      await $deals.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  })
                })"
              />
              <ButtonRefresh
                toast="Campos relacionados al negocio actualizada correctamente."
                :disabled="!state.dealId"
                @click="onUpdateDealId(state.dealId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del negocio"
            :default-open="false"
            :entries="information.deal"
          />

          <UFormGroup
            label="Cuenta"
            name="accountId"
            required
          >
            <UButtonGroup class="w-full">
              <InputReadOnly
                :model-value="utils.maps.accountId.value.get(state.accountId)?.name ?? 'Selecciona un negocio'"
                class="flex-1"
              />
              <UButton icon="i-mdi-plus" disabled />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la cuenta"
            :entries="information.account"
            :default-open="false"
          />

          <UFormGroup
            label="Sucursal"
            name="branchId"
            :required="isRequired(schema, 'branchId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.branchId"
                :options="utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('branchId') || !state.dealId"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('branchId') || !state.accountId"
                @click="openNested(() => branches$.slideoverOpenCreate({
                  data: { accountId: state.accountId },
                  disabledFields: ['accountId'],
                  onSubmit: async (event) => {
                    const branch = await branches$.onSubmitCreate(event.data)
                    state.branchId = branch!.id
                    await $branches.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la sucursal"
            :default-open="false"
            :entries="information.branch"
          />

          <UFormGroup
            label="Contactos"
            name="contactIds"
            :required="isRequired(schema, 'contactIds')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactIds"
                :options="utils.maps.contactByAccountId.value.get(state.accountId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('contactIds') || !state.dealId"
                multiple
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactIds') || !state.dealId"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'customer', status: 'active', accountId: state.accountId },
                  disabledFields: ['type', 'accountId'],
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactIds = [...state.contactIds!, contact!.id]
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Usuarios"
            name="userIds"
            :required="isRequired(schema, 'userIds')"
          >
            <SelectMenuBase
              v-model="state.userIds"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userIds')"
              multiple
              class="flex-1"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsActivities.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="optionsActivities.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(schema, 'dateStart')"
            :is-required-end-date="isRequired(schema, 'dateEnd')"
          />

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `activities/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppActivitiesFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.activities"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
