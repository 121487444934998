import type { FormErrorEvent } from '#ui/types'
import { useClipboard } from '@vueuse/core'

export const open = (url: string, target = '_blank') => window?.open(url, target)

/**
 * WORKAROUND: this is a hack to close the modal and slideover before
 * opening another one since nested modals/slideovers are not supported
 * in @nuxt/ui v2 due to a current limitation with HeadlessUI Dialog,
 * TODO: remove this utility once nested modals/slideovers supported in v3
 */
export const openNested = async (callback: () => void) => {
  useModal().close() // either
  useSlideover().close() // or
  await new Promise(res => setTimeout(res, 900)) // wait at least closing transition duration (not more, 900ms works)
  callback()
}

export const copyToClipboard = (text: string) => {
  const { copy } = useClipboard()

  copy(text).then(() => {
    useToastAlert().success(`Copiado al portapapeles`)
  }).catch((err) => {
    useToastAlert().error(`Error al copiar al portapapeles`)
    console.error('Error copying text to clipboard:', err)
  })
}

// views:

export const isGroupingBy = (view: ViewSettings<unknown>, key: string) => {
  const isGroupableView = ['kanban'].includes(view.selectedView)
  const isInGroups = view.groups?.some(group => group.value === key)
  return isGroupableView && isInGroups && [key].includes(view.group!)
}

// theme:

export const UI_DASHBOARD_NAVBAR = {
  wrapper: 'bg-primary-100 dark:bg-primary-900 !border-transparent',
  left: 'flex-1 items-center',
}

export const UI_DASHBOARD_SIDEBAR = {
  container: 'bg-primary-100 dark:bg-primary-900',
}

export const UI_DASHBOARD_SIDEBAR_LINK = {
  active: 'before:bg-primary-200 dark:before:bg-primary-800',
  inactive: 'hover:before:bg-primary-200/50 dark:hover:before:bg-primary-800/50',
}

// forms:

export const onFormError = (event: FormErrorEvent) => {
  const element = document.getElementById(event.errors[0]!.id)
  element?.focus()
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  useToastAlert().error({
    title: `${event.errors.length} errores de validación`,
    description: 'Revisa los campos marcados en rojo y vuelve a intentarlo.',
  })
  event.errors.forEach((error) => {
    console.warn(`${error.message} '${error.path}'`)
  })
}

// Fieldset 1 column with padding and border
export const FIELDSET = 'w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg'
// Fieldset 1 column without padding nor border
export const FIELDSET_FULL = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2` // without padding nor border
// Fieldset 2 columns with padding and border
export const FIELDSET_FULL_2 = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
// Fieldset 12 columns with padding and border
export const FIELDSET_FULL_12 = `w-full grid grid-cols-1 md:grid-cols-12 gap-2 !mt-4 !mb-2 p-4  border rounded-lg` // for 12 columns
