import { ModalDelete, SlideoverInformation, SlideoverPreview } from '#components'
import type { TRecord } from '#shared/types'
import type { ComponentProps } from 'vue-component-type-helpers'

export type TRecord = {
  id: string
  [k: string]: unknown
}

export type TRecordUtils = {
  options: Option[] | ComputedRef<Option[]>
  maps: Record<string, unknown[]> | ComputedRef<Record<string, unknown[]>>
  [k: string]: unknown
}

/**
 * A shared composable with common utilities for records
 */
export const useRecords = <T extends TRecord>(record: TRecord) => {
  const slideover = useSlideover()
  const modal = useModal()

  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<T>(record)

  const slideoverOpenInformation = (record: TRecord, row: T) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.open(SlideoverInformation, { data: row, recordId: row.id, record })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const slideoverOpenFilePreview = async (id: string, file: { pathname: string }, title?: string) => {
    // DISABLED: disable automatic blob creation since it conflicts with slideover closing on submit
    // // WORKAROUND: force regenerate the file each time the preview is opened to
    // // ensure it's up to date for example when doing CRUD on children (items)
    // await onSubmitUpdate({ id, updatedAt: new Date().toISOString() }, false)
    return new Promise<T>((resolve, reject) => {
      try {
        const url = new URL(`/api/blob/${file.pathname}`, window.location.origin)
        url.searchParams.set('v', Date.now().toString()) // cache-busting mechanism
        slideover.open(SlideoverPreview, {
          src: url.href,
          title: title ?? 'Vista previa',
          onClickRegenerate: async () => {
            try {
              const result = await onSubmitUpdate({ id, updatedAt: new Date().toISOString() } as Partial<T>, false)
              useToastAlert().success('Archivo regenerado con éxito')
              resolve(result)
            }
            catch (error) {
              useToastAlert().error('Error al regenerar el archivo')
              console.error(error)
            }
          },
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const modalOpenDelete = (props: ComponentProps<typeof ModalDelete>) => {
    return new Promise<T[]>((resolve, reject) => {
      try {
        modal.open(ModalDelete, {
          title: 'Eliminar registro',
          onSubmit: props?.onSubmit ?? (async (event) => {
            const result = await onSubmitDelete([event.data])
            modal.close()
            resolve(result)
          }),
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  }
}
