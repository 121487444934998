<script setup lang="ts">
const model = defineModel<boolean>()

const props = defineProps<{
  title?: string
  description?: string
  view: Required<ViewSettings<unknown>>
  onUpdateView: (view: Required<ViewSettings<unknown>>) => void
  disabledFields?: string[]
}>()

const state = ref(props.view)
watch(state, props.onUpdateView, { deep: true }) // WORKAROUND: modelValue is not reactive somehow
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title
    :description
  >
    <UFormGroup
      label="Vista"
      name="selectedView"
    >
      <UButtonGroup>
        <UButton
          v-for="(v, i) in state.views"
          :key="`view-${i}-${v.value}`"
          variant="outline"
          :color="state.selectedView === v.value ? 'primary' : 'gray'"
          :icon="v.icon"
          :label="v.label"
          @click="state.selectedView = v.value"
        />
      </UButtonGroup>
    </UFormGroup>

    <!-- SHARED -->
    <UFormGroup
      :label="'Contraer vista'"
      name="compact"
    >
      <!-- <UIcon name="i-mdi-magnify-expand" class="mr-2" /> -->
      <UToggle v-model="state.compact" />
    </UFormGroup>

    <!-- TABLE -->
    <template v-if="['table'].includes(state.selectedView)">
      <UFormGroup
        label="Columnas"
        name="visibleColumns"
      >
        <SelectMenuBase
          v-model="state.visibleColumns"
          icon="i-mdi-table-column"
          :options="state.columns.filter(c => !!c.label)"
          multiple
          value-attribute="key"
          :popper="{ placement: 'auto-start' }"
          :ui-menu="{ width: 'min-w-max' }"
        >
          <template #label>
            {{ state.visibleColumns?.length }}
          </template>
        </SelectMenuBase>
      </UFormGroup>
    </template>

    <!-- VIEW-SPECIFIC -->
    <template v-if="['table', 'kanban', 'calendar'].includes(state.selectedView)">
      <UFormGroup
        label="Agrupar por"
        name="group"
      >
        <SelectMenuBase
          v-model="state.group"
          icon="i-mdi-format-list-group"
          value-attribute="value"
          :options="state.groups"
          :popper="{ placement: 'auto-start' }"
          :ui-menu="{ width: 'min-w-max' }"
        />
      </UFormGroup>
    </template>
  </UDashboardModal>
</template>
