<script setup lang="ts">
import type { ZodSchema } from 'zod';

const model = defineModel<Quote | Salesorder | Serviceorder>({ required: true })

const props = defineProps<{
  disabledFields?: (keyof Delivery)[]
  hiddenFields?: (keyof Delivery)[]
  disabled?: boolean
  schema: ZodSchema
  utils: QuotesUtils | SalesordersUtils | ServiceordersUtils
  openNested: (callback: () => void) => void
  slideoverOpenEdit: () => void
}>()

const onUpdateDeliverySchedule = (schedule: string) => {
  if (['scheduled'].includes(schedule)) {
    model.value.delivery.isDatetime = false
    model.value.delivery.date = ''
  }
}

const onToggleUseAccountAddress = () => {
  model.value.delivery.useAccountAddress = !model.value.delivery.useAccountAddress
}

const onUpdateAddress = () => {
  if (model.value.delivery.useAccountAddress) {
    const account = props.utils.maps.accountId.value.get(model.value.accountId)
    if (account) {
      model.value.delivery.address = account.billing?.address ?? {} as Address
    }
  }
  else if (model.value.branchId) {
    model.value.delivery.address = props.utils.maps.branchId.value.get(model.value.branchId)?.address ?? {} as Address
  }
}

const onUpdateBranchId = async (branchId: string) => {
  model.value.delivery.address = {} as Address
  onUpdateAddress()
}

watch(() => model.value.delivery.useAccountAddress, () => {
  onUpdateAddress()
})

const FIELDSET = `w-full grid gap-2`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      v-if="!['incoterm'].includes(hiddenFields)"
      label="Términos de entrega"
      name="delivery.incoterm"
      :required="isRequired(schema, 'delivery.incoterm')"
    >
      <SelectMenuBaseInfo
        v-model="model.delivery.incoterm"
        :options="optionsDeliveries.incoterm"
        :disabled="disabledFields?.includes('delivery.incoterm')"
      />
    </UFormGroup>

    <UFormGroup
      v-if="!['schedule'].includes(hiddenFields)"
      label="Tiempo de entrega"
      name="delivery.schedule"
      :required="isRequired(schema, 'delivery.schedule')"
    >
      <SelectMenuBaseInfo
        v-model="model.delivery.schedule"
        :options="optionsDeliveries.schedule"
        :disabled="disabledFields?.includes('delivery.schedule')"
        @update:model-value="onUpdateDeliverySchedule"
      />
    </UFormGroup>

    <UFormGroup
      v-if="['scheduled'].includes(model.delivery?.schedule) && !['date'].includes(hiddenFields)"
      label="Fecha de entrega"
      name="delivery.date"
      :required="isRequired(schema, 'delivery.date')"
    >
      <InputDatetime
        v-model:date="model.delivery.date"
        v-model:is-datetime="model.delivery.isDatetime"
        :disabled="disabledFields?.includes('delivery.date')"
      />
    </UFormGroup>

    <UFormGroup
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm) && !['branchId'].includes(hiddenFields)"
      label="Dirección de entrega"
      name="branchId"
      :required="model.delivery.useAccountAddress && !['EXW', 'pending'].includes(model.delivery?.incoterm)"
    >
      <div
        v-if="model.delivery.useAccountAddress"
        class="w-full flex items-center gap-1"
      >
        <UButton
          icon="i-mdi-checkbox-marked"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm)"
          @click="onToggleUseAccountAddress"
        />
        <div class="w-full flex gap-1 pl-1">
          <UButtonGroup class="flex-1">
            <InputReadOnly
              model-value="Entregar en domicilio fiscal"
              class="flex-1"
            />
            <UButton icon="i-mdi-plus" disabled />
          </UButtonGroup>
          <ButtonRefresh
            toast="Dirección actualizada"
            @click="onUpdateAddress()"
          />
        </div>
      </div>
      <UButtonGroup v-else class="w-full">
        <UButton
          icon="i-mdi-checkbox-blank-outline"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm)"
          @click="onToggleUseAccountAddress"
        />
        <div class="w-full flex gap-1 pl-1">
          <UButtonGroup class="flex-1">
            <SelectMenuBase
              v-model="model.branchId"
              :options="utils.maps.branchesByAccountId?.value.get(model.accountId)?.map(toOption) ?? []"
              class="flex-1"
              :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm) || disabledFields?.includes('branchId')"
              @update:model-value="onUpdateBranchId"
            />
            <UButton
              icon="i-mdi-plus"
              :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm) || disabledFields?.includes('branchId')"
              @click="openNested(() => branches$.slideoverOpenCreate({
                data: { accountId: model.accountId },
                onSubmit: async (event) => {
                  const branch = await branches$.onSubmitCreate(event.data)
                  model.branchId = branch!.id
                  await openNested(() => slideoverOpenEdit({ ...props, data: model }))
                },
              }))"
            />
          </UButtonGroup>
          <ButtonRefresh
            toast="Dirección actualizada"
            @click="onUpdateAddress()"
          />
        </div>
      </UButtonGroup>
    </UFormGroup>

    <FieldsetAddress
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
      v-model="model.delivery.address"
      disabled
      v-bind="{ schema, path: 'delivery.address', disabledFields }"
    />

    <Alert
      type="warning"
      description="Para actualizar la dirección es necesario editar la sucursal o el domicilio fiscal."
    />

    <UFormGroup
      v-if="!['text'].includes(hiddenFields)"
      label="Detalles entrega"
      name="delivery.text"
      :required="isRequired(schema, 'delivery.text')"
    >
      <UTextarea
        v-model="model.delivery.text"
        autoresize
        :disabled="disabledFields?.includes('delivery.text')"
      />
    </UFormGroup>
  </fieldset>
</template>
