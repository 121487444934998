<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import type { Productitem } from '~~/server/utils/db.types'

const props = defineProps<{
  data: Partial<Productitem>
  onSubmit: (event: FormSubmitEvent<Partial<Productitem>>) => void
  title?: string
  disabledFields?: (keyof Productitem | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Productitem>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useProductitems()

const $products = await useFetch<ProductWithRelations[]>('/api/db/products', {
  query: { $with: relationsProducts },
  default: () => [],
})

const utils = useProductitemsUtils({ $products })

const products$ = useProducts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.productitems,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'fiscalData',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateProductId = async (productId: string) => {
  const product = utils.maps.productId.value.get(productId)
  state.value.image = product?.image
  state.value.description = product?.description
  await generateProductName()
}

const generateProductName = async () => {
  if (state.value.productId) {
    const product = utils.maps.productId.value.get(state.value.productId)
    state.value.name = product?.name + ' - Estándar'
  }
}

const information = computed(() => {
  const product = utils.maps.productId.value.get(state.value.productId) as Partial<ProductWithRelations>
  return {
    product: getProductInformation(product),
  }
})

const loading = ref(false)

const onSubmitProductitem = async (event: FormSubmitEvent<Partial<Productitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.productitems"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProductitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Producto"
            name="productId"
            :required="isRequired(schema, 'productId')"
          >
            <div class="w-full flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.productId"
                  :options="utils.options.productId.value"
                  :disabled="disabledFields?.includes('productId')"
                  class="flex-1"
                  @update:model-value="onUpdateProductId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('productId')"
                  @click="openNested(() => products$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const product = await products$.onSubmitCreate(event.data)
                      state.productId = product!.id
                      await $products.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                :disabled="!state.productId"
                @click="onUpdateProductId(state.productId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del producto"
            :default-open="false"
            :entries="information.product"
          />

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex w-full">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                class="flex-1"
              />
              <ButtonGenerateString
                :disabled="disabledFields?.includes('name') || !state.productId"
                @click="generateProductName"
              />
            </UButtonGroup>
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsProductitems.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `productitems/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup
            label="SKU"
            name="sku"
            :required="isRequired(schema, 'sku')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.sku"
                :disabled="disabledFields?.includes('sku')"
                class="flex-1"
              />
              <ButtonGenerateString disabled />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `productitems/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              resize
              :disabled="disabledFields?.includes('description')"
            />
          </UFormGroup>
        </template>

        <template #fiscalData>
          <UFormGroup
            label="Unidad (SAT)"
            name="fiscalData.satUnitKey"
            :required="isRequired(schema, 'fiscalData.satUnitKey')"
          >
            <SelectMenuBase
              v-model="state.fiscalData.satUnitKey"
              :options="optionsSAT.productUnitId"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppProductitemsFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.productitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
