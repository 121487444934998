<script setup lang="ts">
import type { BlobPutOptions } from '@nuxthub/core'
import { vElementHover } from '@vueuse/components'

// NOTE: only accept PDFMake-supported types PNG/JPEG
// to avoid issues later on when generating PDFs
const ACCEPT = 'image/png,image/jpeg'

const model = defineModel<string[]>({ default: () => [] })

const props = defineProps<{
  query?: BlobPutOptions
}>()

const { uploadFile, removeFile } = useApiBlob()

const rerenderKeys = ref(new Map<string, number>())

const onChange = async (files: File[]) => {
  if (!files) return

  const filesArray = Array.from(files)

  const uploadedPathnames = await Promise.all(
    filesArray.map(async (file) => {
      const blobObject = await uploadFile(file, props.query)
      const newPathname = `/api/blob/${blobObject.pathname}`
      rerenderKeys.value.set(newPathname, Date.now())
      return newPathname
    }),
  )

  model.value = [...model.value, ...uploadedPathnames]
}

const isHovered = ref<boolean[]>(model.value.map(() => false))

const onRemove = async (pathname: string) => {
  model.value = model.value.filter(value => value !== pathname)
  await removeFile(pathname)
  rerenderKeys.value.delete(pathname)
}
</script>

<template>
  <div class="flex items-center gap-3 flex-wrap">
    <UInput
      v-bind="$attrs"
      type="file"
      multiple
      :accept="ACCEPT"
      placeholder="Cargar imágenes"
      class="w-full"
      @change="onChange"
    />

    <div v-if="model?.length" class="w-full flex flex-wrap gap-2 items-center mt-2">
      <div
        v-for="(pathname, i) in model"
        :key="`${pathname}-${rerenderKeys.get(pathname) || ''}`"
        class="grid gap-2 relative"
      >
        <UAvatar
          :key="rerenderKeys.get(pathname)"
          v-element-hover="(state: boolean) => isHovered[i] = state"
          :src="pathname"
          :class="{ 'cursor-no-drop': isHovered[i] }"
          title="Haz clic para eliminar la imagen"
          size="md"
          @click="() => onRemove(pathname)"
        />
      </div>
    </div>
  </div>
</template>
