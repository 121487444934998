import { ToWords } from 'to-words'

export const toFixed = (value = 0, digits = 0) =>
  Intl.NumberFormat('es-ES', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)

export const toCurrency = (value = 0, currency: 'MXN' | 'USD' = 'USD') => {
  const locales = { MXN: 'es-MX', USD: 'en-US', EUR: 'es-ES' }
  return new Intl.NumberFormat(locales[currency], {
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency,
  }).format(value)
}

export const toTitleCase = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const toSpelledCurrency = (currency: 'USD' | 'MXN' = 'USD') => {
  let currencyName: string
  let currencySymbol: string
  let fractionalUnitName: string
  let fractionalUnitSymbol: string

  if (currency === 'USD') {
    currencyName = 'Dólar'
    currencySymbol = '$'
    fractionalUnitName = 'Centavos'
    fractionalUnitSymbol = 'c'
  }
  else if (currency === 'MXN') {
    currencyName = 'Peso'
    currencySymbol = '$'
    fractionalUnitName = 'Centavos'
    fractionalUnitSymbol = '¢'
  }
  else {
    throw new Error('Unsupported currency')
  }

  return new ToWords({
    localeCode: 'es-ES',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: currencyName,
        plural: `${currencyName}${currency === 'USD' ? 'es' : 's'}`,
        symbol: currencySymbol,
        fractionalUnit: {
          name: fractionalUnitName,
          plural: `${fractionalUnitName}`,
          symbol: fractionalUnitSymbol,
        },
      },
    },
  })
}

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

export const toPercent = (value = 0) =>
  new Intl.NumberFormat('es-ES', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(value)
