import { AppProductionordersSlideover } from '#components'
import { z, type SalesorderWithRelations } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppProductionordersSlideoverProps = ComponentProps<typeof AppProductionordersSlideover>

export type ProductionordersUtils = ReturnType<typeof useProductionordersUtils>

export const useProductionorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Productionorder>('productionorders')

  const slideoverOpenCreate = (props: AppProductionordersSlideoverProps) => {
    return new Promise<Productionorder>((resolve, reject) => {
      const data = getDefaultProductionorder(props.data)
      slideover.open(AppProductionordersSlideover, {
        title: 'Registrar orden de producción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductionordersSlideoverProps) => {
    return new Promise<Productionorder>((resolve, reject) => {
      const data = getDefaultProductionorder(props.data)
      slideover.open(AppProductionordersSlideover, {
        title: 'Editar orden de producción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    stage: z.string(),
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Productionorder, refresh: () => Promise<void>, utils: ProductionordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'productionorders', row, refresh, utils),
      getDropdownItem('items', 'productionorders', row, refresh, utils),
      getDropdownItem('copyId', 'productionorders', row, refresh, utils),
      getDropdownItem('copyUid', 'productionorders', row, refresh, utils),
      getDropdownItem('archive', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'productionorders', row, refresh, utils),
      getDropdownItem('files', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('password', 'productionorders', row, refresh, utils),
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideoverOpenFilePreview(row.id, row.fileProductionorderPdf),
      },
    ],
    getDropdownItemGroup('status', 'productionorders', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Agregar item',
        disabled: row.$inmutable,
        icon: ICONS.productionorderitems,
        click: () => {
          const productionorderitems$ = useProductionorderitems()
          productionorderitems$.slideoverOpenCreate({
            data: { productionorderId: row.id },
            disabledFields: ['productionorderId'],
          }).then(refresh)
        },
      } as DropdownItem,
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'productionorders', row, refresh, utils),
      getDropdownItem('inmutable', 'productionorders', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'productionorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: ProductionorderWithRelations, utils: ProductionordersUtils) => {
    const [salesorder] = await $fetch<SalesorderWithRelations[]>(`/api/db/salesorders`, {
      query: { id: data.salesorderId, $with: relationsSalesorders },
    })
    const documentDefinition = await createPdfmakeDocumentDefinitionProductionorders(data, utils, salesorder!)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the productionorder.files array (to be used after POST/PATCH)
  async function createFileProductionorderPdf(data: Partial<Productionorder>, utils: ProductionordersUtils) {
    const [existingData] = await $fetch<ProductionorderWithRelations[]>(`/api/db/productionorders`, {
      query: { id: data.id, $with: relationsProductionorders },
    })
    const newData = { ...existingData, ...data } as ProductionorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'productionorder.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `productionorders/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modal,
    modalOpenDelete,
    schema,
    getDropdownItems,
    createPdf,
    createFileProductionorderPdf,
  }
}

export const useProductionordersUtils = ({ $salesorders, $users }: {
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsProductionorders,
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsProductionorders.type, 'value'),
    stage: toMapByKey(optionsProductionorders.stage, 'value'),
    status: toMapByKey(optionsProductionorders.status, 'value'),
    priority: toMapByKey(optionsProductionorders.priority, 'value'),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
  }

  return { options, maps }
}
