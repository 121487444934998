import { createId, type Options } from '#netzo/utils/core/index'
import { merge } from 'lodash-es'

export const getDefaultServiceorderitem = (data: Partial<Serviceorderitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'pending',
    status: 'pending',
    images: [],
    tasks: [],
    tags: [],
    files: [],
    data: getDefaultServiceorderitemData(data?.data ?? {}),
  }, data)
}

export const optionsServiceorderitems = {
  type: [
    { value: 'pending', label: 'Por definir', description: 'Items que aún no han sido procesados.', icon: 'i-mdi-circle-slice-1', color: 'blue' },
    { value: 'pickup', label: 'Recolección', description: 'Items para recolección en el lugar designado.', icon: 'i-mdi-package-variant', color: 'blue' },
    { value: 'delivery', label: 'Entrega', description: 'Items para la entrega de productos a la dirección del cliente.', icon: 'i-mdi-truck', color: 'amber' },
    { value: 'installation', label: 'Instalación', description: 'Items que requieren la instalación de productos en la ubicación del cliente.', icon: 'i-mdi-wrench', color: 'green' },
    { value: 'service', label: 'Servicio', description: 'Items para servicios técnicos en el lugar designado.', icon: 'i-mdi-wrench', color: 'green' },
  ],
  status: optionsSharedApp.statusProcess,
  data: {
    approvalStatus: [
      { value: 'pending', label: 'Pendiente', description: 'Servicio pendiente de aprobación por el cliente.', icon: 'i-mdi-clock', color: 'gray' },
      { value: 'approved', label: 'Aprobado', description: 'Servicio aprobado por el cliente y completado con éxito.', icon: 'i-mdi-thumb-up', color: 'green' },
      { value: 'rejected', label: 'Rechazado', description: 'Servicio rechazado por el cliente o no completado con éxito.', icon: 'i-mdi-thumb-down', color: 'red' },
    ],
  },
} satisfies Options

export const optionsServiceorderitemsMap = {
  Tipo: optionsServiceorderitems.type,
  Estado: optionsServiceorderitems.status,
  Aprobación: {
    Estado: optionsServiceorderitems.data.approvalStatus,
  },
}

export const relationsServiceorderitems: Record<string, boolean> = {
  serviceitem: true,
  user: true,
  serviceorder: true,
}

export type ServiceorderitemWithRelations = Serviceorderitem & {
  serviceitem: Serviceitem
  user: User
  serviceorder: Serviceorder
}

export const viewSettingsServiceorderitems: ViewSettings<Serviceorderitem> = {
  record: 'serviceorderitems',
  views: optionsShared.views.filter(view => ['table', 'kanban'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
    },
    {
      key: 'serviceitemId',
      label: 'Item',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'serviceorder.type',
      label: 'Tipo de Orden',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Responsable',
      sortable: true,
    },
    {
      key: 'serviceorderId',
      label: 'Orden de servicio',
      sortable: true,
    },
    {
      key: 'quantityPlanned',
      label: 'Cantidad (plan)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantityActual',
      label: 'Cantidad (real)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'images',
      label: 'Imágenes',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'approval.images',
      label: 'Imágenes para aprobación',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'serviceorderId',
      label: 'Orden de servicio',
      sortable: true,
    },
    {
      key: 'tasks',
      label: 'Tareas',
      class: 'min-w-[200px]',
    },
    {
      key: 'text',
      label: 'Especificaciones',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsServiceorderitems.type },
    { label: 'Estado', value: 'status', options: optionsServiceorderitems.status },
  ],
  group: 'status',
}
