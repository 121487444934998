<script setup lang="ts">
const model = defineModel<Serviceorder>({ required: true })

const props = defineProps<{
  utils: ServiceordersUtils
  disabled?: boolean
}>()

const serviceorderitemIds = ref<string[]>(model.value.data?.items?.map(item => item.id) ?? [])

const onUpdateModelValue = (value: string[]) => {
  model.value.data.items = value.map((id) => {
    const item = model.value.serviceorderitems.find(item => item.id === id)
    return { ...item, approvalRequired: true }
  })
}
</script>

<template>
  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Portal de Servicio
    </summary>
    <fieldset :class="FIELDSET" :disabled="disabled">
      <UFormGroup
        label="Items que requieren aprobación"
        ah
        description="Selecciona los items que requieren aprobación externa. Estos items serán visibles en el portal de servicio en la etapa de 'Aprobación' para que el cliente pueda aprobarlos."
        name="serviceorderitemIds"
      >
        <SelectMenuBase
          v-model="serviceorderitemIds"
          :options="model.serviceorderitems?.map(toOption) ?? []"
          multiple
          @update:model-value="onUpdateModelValue"
        />
      </UFormGroup>

      <UFormGroup
        :label="`NPS (Net Promoter Score ${model.data.nps}/10)`"
        name="data.nps"
        class="mb-4"
      >
        <InputNps v-model="model.data.nps" :disabled="!['approval'].includes(model.stage)" />
      </UFormGroup>

      <UFormGroup
        label="Comentarios"
        name="data.approvalText"
      >
        <UTextarea
          v-model="model.data.approvalText"
          autoresize
          :disabled="!['approval'].includes(model.stage)"
        />
      </UFormGroup>

      <UFormGroup
        label="Firmado por"
        name="data.approvedBy"
      >
        <UInput v-model="model.data.approvedBy" />
      </UFormGroup>

      <UFormGroup
        label="Firmado por"
        name="data.approvedBy"
      >
        <InputSignature
          v-model="model.data.approvedBy"
          :disabled="!['approval'].includes(model.stage)"
          @blur="() => model.data.approvedAt = new Date().toISOString()"
        />
      </UFormGroup>

      <UFormGroup
        label="Firmado en"
        name="data.approvedAt"
      >
        <InputDatetime
          v-model:date="model.data.approvedAt"
          :model-value:is-datetime="true"
          hide-checkbox
          :disabled="!['approval'].includes(model.stage)"
        />
      </UFormGroup>

      <Alert
        type="info"
        description="Para poder cambiar los campos de la aprobación, es necesario que el item se encuentre en la etapa de aprobación."
        class="mt-4"
      />
    </fieldset>
  </details>
</template>
