<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Product>
  onSubmit: (event: FormSubmitEvent<Partial<Product>>) => void
  title?: string
  disabledFields?: (keyof Product | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Product>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useProducts()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { type: ['supplier'], $columns: ['id', 'name', 'status', 'domain', 'image'] },
  default: () => [],
})
const $applications = await useFetch<Application[]>('/api/db/applications', {
  default: () => [],
})
const $categories = await useFetch<Category[]>('/api/db/categories', {
  query: { type: ['products'] },
  default: () => [],
})

const utils = useProductsUtils({ $accounts, $applications, $categories })

const accounts$ = useAccounts()
const categories$ = useCategories()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.products,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'fiscalData',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateCategoryId = async (categoryId: string) => {
  const category = utils.maps.categoryId.value.get(categoryId)
  state.value.image = category?.image
}

const information = computed(() => {
  const category = utils.maps.categoryId.value.get(state.value.categoryId) as Partial<Category>
  const supplier = utils.maps.accountId.value.get(state.value.supplierId) as Partial<Account>
  const manufacturer = utils.maps.accountId.value.get(state.value.manufacturerId) as Partial<Account>
  return {
    category: getCategoryInformation(category),
    supplier: getAccountInformation(supplier),
    manufacturer: getAccountInformation(manufacturer),
  }
})

const loading = ref(false)

const onSubmitProduct = async (event: FormSubmitEvent<Partial<Product>>) => {
  loading.value = true
  await props.onSubmit(event)
  router.push(`/${moduleId.value}/products/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.products"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProduct"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsProducts.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Categoría"
            name="categoryId"
            :required="isRequired(schema, 'categoryId')"
          >
            <div class="w-full flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.categoryId"
                  :options="utils.options.categoryId.value"
                  :disabled="disabledFields?.includes('categoryId') || !state.type"
                  class="flex-1"
                  @update:model-value="onUpdateCategoryId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('categoryId')"
                  @click="openNested(() => categories$.slideoverOpenCreate({
                    data: { type: 'products' },
                    disabledFields: ['type'],
                    onSubmit: async (event) => {
                      const category = await categories$.onSubmitCreate(event.data)
                      state.categoryId = category!.id
                      state.image = category!.image
                      await $categories.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                :disabled="!state.categoryId"
                @click="onUpdateCategoryId(state.categoryId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la categoría"
            :default-open="false"
            :entries="information.category"
          />

          <UFormGroup
            label="Proveedor"
            name="supplierId"
            :required="isRequired(schema, 'supplierId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.supplierId"
                :options="utils.options.accountId.value"
                :disabled="disabledFields?.includes('supplierId')"
                class="flex-1"
                @update:model-value="onUpdateSupplierId"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('supplierId')"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'supplier' },
                  disabledFields: ['type'],
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.supplierId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del proveedor"
            :default-open="false"
            :entries="information.supplier"
          />

          <UFormGroup
            label="Fabricante"
            name="manufacturerId"
            :required="isRequired(schema, 'manufacturerId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.manufacturerId"
                :options="utils.options.accountId.value"
                :disabled="disabledFields?.includes('manufacturerId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('manufacturerId')"
                @click="openNested(() => accounts$.slideoverOpenCreate({
                  data: { type: 'supplier' },
                  disabledFields: ['type'],
                  onSubmit: async (event) => {
                    const account = await accounts$.onSubmitCreate(event.data)
                    state.manufacturerId = account!.id
                    await $accounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <ViewRendererInformation
            title="Información del fabricante"
            :default-open="false"
            :entries="information.manufacturer"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsProducts.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `products/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `products/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              resize
              :disabled="disabledFields?.includes('description')"
            />
          </UFormGroup>
        </template>

        <template #fiscalData>
          <UFormGroup
            label="Clave Producto (SAT)"
            name="fiscalData.satProductServiceKey"
            :required="isRequired(schema, 'fiscalData.satProductServiceKey')"
            help="Consulta el catálogo de productos y servicios del SAT"
          >
            <InputBaseLink
              v-model="state.fiscalData!.satProductServiceKey"
              :disabled="disabledFields?.includes('fiscalData.satProductServiceKey')"
              href="https://www.sat.gob.mx/consultas/53693/catalogo-de-productos-y-servicios"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppProductsFieldsetData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.products"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
