import { AppServiceordersModalStageApproval, AppServiceordersSlideover } from '#components'
import { z, type ServiceorderWithRelations } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppServiceordersSlideoverProps = ComponentProps<typeof AppServiceordersSlideover>

export type ServiceordersUtils = ReturnType<typeof useServiceordersUtils>

export const useServiceorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Serviceorder>('serviceorders')

  const slideoverOpenCreate = (props: AppServiceordersSlideoverProps) => {
    return new Promise<Serviceorderorder>((resolve, reject) => {
      const data = getDefaultServiceorder(props.data)
      slideover.open(AppServiceordersSlideover, {
        title: 'Registrar orden de servicio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppServiceordersSlideoverProps) => {
    return new Promise<Serviceorderorder>((resolve, reject) => {
      const data = getDefaultServiceorder(props.data)
      slideover.open(AppServiceordersSlideover, {
        title: 'Editar orden de servicio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const modalOpenStageApproval = (props: ComponentProps<typeof AppServiceordersModalStageApproval>) => {
    return new Promise<Serviceorder>((resolve, reject) => {
      modal.open(AppServiceordersModalStageApproval, {
        data: props.data,
        onSubmit: async (event) => {
          try {
            const { id, data = {} } = props.data
            data.status = 'approved'
            data.isDatetime = true
            data.date = new Date().toISOString()
            const result = await onSubmitUpdate({ id, data }, false) // ensures only data is modified
            modal.close()
            useToastAlert().success('Aprobación finalizada con éxito.')
            resolve(result)
          }
          catch (error) {
            modal.close()
            useToastAlert().error('Error al finalizar la aprobación.')
            reject(error)
          }
        },
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    stage: z.string(),
    status: z.string(),
    priority: z.string(),
    uid: z.string(),
    name: z.string(),
    isDatetime: z.boolean(),
    address: addressSchema.optional(),
    accountId: z.string(),
    salesorderId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Serviceorder, refresh: () => Promise<void>, utils: ServiceordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'serviceorders', row, refresh, utils),
      getDropdownItem('items', 'serviceorders', row, refresh, utils),
      getDropdownItem('copyId', 'serviceorders', row, refresh, utils),
      getDropdownItem('copyUid', 'serviceorders', row, refresh, utils),
      getDropdownItem('archive', 'serviceorders', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'serviceorders', row, refresh, utils),
      getDropdownItem('files', 'serviceorders', row, refresh, utils),
    ],
    [
      getDropdownItem('password', 'serviceorders', row, refresh, utils),
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideoverOpenFilePreview(row.id, row.fileServiceorderPdf),
      },
    ],
    // Status Actions
    getDropdownItemGroup('status', 'serviceorders', row, refresh, utils),
    // resource actions:
    [
      {
        label: 'Agregar item',
        disabled: row.$inmutable,
        icon: ICONS.serviceorderitems,
        click: () => {
          const serviceorderitems$ = useServiceorderitems()
          serviceorderitems$.slideoverOpenCreate({
            data: { serviceorderId: row.id },
            disabledFields: ['serviceorderId'],
          }).then(refresh)
        },
      } as DropdownItem,
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'serviceorders', row, refresh, utils),
      getDropdownItem('inmutable', 'serviceorders', row, refresh, utils),
    ],
    [
      getDropdownItem('delete', 'serviceorders', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: ServiceorderWithRelations, utils: ServiceordersUtils) => {
    const [salesorder] = await $fetch<SalesorderWithRelations[]>(`/api/db/salesorders`, {
      query: { id: data.salesorderId, $with: relationsSalesorders },
    })
    const documentDefinition = await createPdfmakeDocumentDefinitionServiceorders(data, utils, salesorder!)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the serviceorder.files array (to be used after POST/PATCH)
  async function createFileServiceorderPdf(data: Partial<Serviceorder>, utils: ServiceordersUtils) {
    const [existingData] = await $fetch<ServiceorderWithRelations[]>(`/api/db/serviceorders`, {
      query: { id: data.id, $with: relationsServiceorders },
    })
    const newData = { ...existingData, ...data } as ServiceorderWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'serviceorder.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `serviceorders/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    modalOpenStageApproval,
    schema,
    getDropdownItems,
    createPdf,
    createFileServiceorderPdf,
  }
}

export const useServiceordersUtils = ({ $accounts, $branches, $contacts, $productionorders, $salesorders, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $productionorders?: Awaited<ReturnType<typeof useFetch<Productionorder[]>>>
  $salesorders?: Awaited<ReturnType<typeof useFetch<Salesorder[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsServiceorders,
    delivery: optionsDeliveries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    productionorderId: computed(() => $productionorders?.data.value.map(toOption)),
    salesorderId: computed(() => $salesorders?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsServiceorders.type, 'value'),
    stage: toMapByKey(optionsServiceorders.stage, 'value'),
    status: toMapByKey(optionsServiceorders.status, 'value'),
    priority: toMapByKey(optionsServiceorders.priority, 'value'),
    productionorder: {
      stage: toMapByKey(optionsProductionorders.stage, 'value'),
      status: toMapByKey(optionsProductionorders.status, 'value'),
    },
    data: {
      nps: toMapByKey(optionsSharedApp.nps, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    productionorderId: computed(() => toMapByKey($productionorders?.data.value, 'id')),
    salesorderId: computed(() => toMapByKey($salesorders?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    productionordersByType: computed(() => toMapByGroupKey($productionorders?.data.value, 'type')),
    productionordersBySalesorderId: computed(() => toMapByGroupKey($productionorders?.data.value, 'salesorderId')),
  }

  return { options, maps }
}
