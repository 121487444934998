import type { ExtractTablesWithRelations } from 'drizzle-orm'
import { merge } from 'lodash-es'
import type * as schema from '../../server/database/schema'

export type Address = {
  street: string
  numberExterior: string
  numberInterior?: string
  neighborhood?: string
  city: string
  district?: string
  state: string
  country: string
  postalCode: string
}
export type Billing = {
  type: 'individual' | 'legalEntity' | 'foreignLegalEntity' | 'genericRFC'
  taxId: string
  taxCountryCode: 'MX' | 'US' | string
  legalName: string
  satFiscalRegime: string
  address: Address
}
export type Checklist = {
  resolved: boolean
  text: string
}
export type DateRange = {
  isDatetime: boolean
  dateStart: string
  dateEnd: string
}
export type Delivery = {
  incoterm: string
  schedule: string
  isDatetime?: boolean
  date?: string
  useAccountAddress: boolean
  address?: Address
  text?: string
}
export type TRecord = keyof ExtractTablesWithRelations<typeof schema>
export type ExchangeRate = {
  date: string
  value: number
}
export type FiscalData = {
  satProductServiceKey?: string
  satUnitKey?: string
  satTaxObject?: string
}
export type I18n = {
  [key: string]: Record<string, unknown>
}
export type Issuer = Billing & {
  phone: string
  email: string
  issuedIn: string
  cfdiType: string
}
export type Item = {
  id: string
  type: 'products' | 'services'
  name: string
  description?: string
  image?: string
  sku?: string
  quantity: number
  data?: {
    [key: string]: string | number | boolean
  }
  productitemId?: string
  serviceitemId?: string
}
export type ItemDiscount = {
  type: 'percent' | 'number'
  value: number
  amount: number
}
export type ItemTaxes = {
  transfers?: {
    enabled: boolean
    tax: string
    satFactorType: string
    satRateOrQuota: number
    base: number
    amount: number
  }[]
  retentions?: {
    enabled: boolean
    tax: string
    satFactorType: string
    satRateOrQuota: number
    base: number
    amount: number
  }[]
}
export type Payment = {
  satCfdiUse: string
  satPaymentMethod: string
  satPaymentForm: string
  conditions: string
  currency: 'MXN' | 'USD'
  exchangeRate: {
    date: string
    value: number
  }
}
export type PaymentAccount = {
  id: Financialaccount['id']
  name: Bank['name']
  currency: Financialaccount['currency']
  swift: Bank['swift']
  clabe: Financialaccount['clabe']
  iban: Financialaccount['iban']
  accountNumber: Financialaccount['accountNumber']
  routingNumber: Financialaccount['routingNumber']
}
export type PdfSettings = {
  watermark: {
    enabled: boolean
    text: string
    color: string
    opacity: number
    bold: boolean
    italic: boolean
    fontSize: number
    angle: number
  }
  password: {
    enabled: boolean
    value: string
  }
  details: boolean
  images: boolean
}
export type QuoteExpedition = {
  isDatetime: boolean
  issueDate: string
  expirationDate: string
}

////////////////////////////////////////////////////////////////////////////////////////////////////

type Metadata = 'createdAt' | 'updatedAt' | 'deletedAt' | 'createdBy' | 'updatedBy' | 'deletedBy'

export type Account = typeof schema.accounts.$inferSelect
export type AccountData = Omit<typeof schema.accounts.$inferInsert, Metadata>

export type Activity = typeof schema.activities.$inferSelect
export type ActivityData = Omit<typeof schema.activities.$inferInsert, Metadata>

export type Application = typeof schema.applications.$inferSelect
export type ApplicationData = Omit<typeof schema.applications.$inferInsert, Metadata>

export type Bank = typeof schema.banks.$inferSelect
export type BankData = Omit<typeof schema.banks.$inferInsert, Metadata>

export type Branch = typeof schema.branches.$inferSelect
export type BranchData = Omit<typeof schema.branches.$inferInsert, Metadata>

export type Campaign = typeof schema.campaigns.$inferSelect
export type CampaignData = Omit<typeof schema.campaigns.$inferInsert, Metadata>

export type Category = typeof schema.categories.$inferSelect
export type CategoryData = Omit<typeof schema.categories.$inferInsert, Metadata>

export type Contact = typeof schema.contacts.$inferSelect
export type ContactData = Omit<typeof schema.contacts.$inferInsert, Metadata>

export type Deal = typeof schema.deals.$inferSelect
export type DealData = Omit<typeof schema.deals.$inferInsert, Metadata>

export type Financialaccount = typeof schema.financialaccounts.$inferSelect
export type FinancialaccountData = Omit<typeof schema.financialaccounts.$inferInsert, Metadata>

export type Industry = typeof schema.industries.$inferSelect
export type IndustryData = Omit<typeof schema.industries.$inferInsert, Metadata>

export type Inquiry = typeof schema.inquiries.$inferSelect
export type InquiryData = Omit<typeof schema.inquiries.$inferInsert, Metadata>

export type Message = typeof schema.messages.$inferSelect
export type MessageData = Omit<typeof schema.messages.$inferInsert, Metadata>

export type Productionorder = typeof schema.productionorders.$inferSelect
export type Productionorderitem = Omit<typeof schema.productionorderitems.$inferInsert, Metadata>

export type Product = typeof schema.products.$inferSelect
export type ProductData = Omit<typeof schema.products.$inferInsert, Metadata>

export type Productitem = typeof schema.productitems.$inferSelect
export type ProductitemData = Omit<typeof schema.productitems.$inferInsert, Metadata>

export type Pricelist = typeof schema.pricelists.$inferSelect
export type PricelistData = Omit<typeof schema.pricelists.$inferInsert, Metadata>

export type Pricelistitem = typeof schema.pricelistitems.$inferSelect
export type PricelistitemData = Omit<typeof schema.pricelistitems.$inferInsert, Metadata>

export type Quote = typeof schema.quotes.$inferSelect
export type QuoteData = Omit<typeof schema.quotes.$inferInsert, Metadata>

export type Quoteitem = typeof schema.quoteitems.$inferSelect
export type QuoteitemData = Omit<typeof schema.quoteitems.$inferInsert, Metadata>

export type Salesorder = typeof schema.salesorders.$inferSelect
export type SalesorderData = Omit<typeof schema.salesorders.$inferInsert, Metadata>

export type Salesorderitem = typeof schema.salesorderitems.$inferSelect
export type SalesorderitemData = Omit<typeof schema.salesorderitems.$inferInsert, Metadata>

export type Service = typeof schema.services.$inferSelect
export type ServiceData = Omit<typeof schema.services.$inferInsert, Metadata>

export type Serviceitem = typeof schema.serviceitems.$inferSelect
export type ServiceitemData = Omit<typeof schema.serviceitems.$inferInsert, Metadata>

export type Serviceorder = typeof schema.serviceorders.$inferSelect
export type ServiceorderData = Omit<typeof schema.serviceorders.$inferInsert, Metadata>

export type Serviceorderitem = typeof schema.serviceorderitems.$inferSelect
export type ServiceorderitemData = Omit<typeof schema.serviceorderitems.$inferInsert, Metadata>

export type Transaction = typeof schema.transactions.$inferSelect
export type TransactionData = Omit<typeof schema.transactions.$inferInsert, Metadata>

////////////////////////////////////////////////////////////////////////////////////////////////////

export type Apikey = typeof schema.apikeys.$inferSelect
export type ApikeyData = Omit<typeof schema.apikeys.$inferInsert, Metadata>

export type Authcredentials = typeof schema.authcredentials.$inferSelect
export type AuthcredentialsData = typeof schema.authcredentials.$inferInsert

export type Authemailverificationcode = typeof schema.authemailverificationcodes.$inferSelect
export type AuthemailverificationcodeData = Omit<typeof schema.authemailverificationcodes.$inferInsert, Metadata>

export type Authinvitation = typeof schema.authinvitations.$inferSelect
export type AuthinvitationData = Omit<typeof schema.authinvitations.$inferInsert, Metadata>

export type Authoauthaccount = typeof schema.authoauthaccounts.$inferSelect
export type AuthoauthaccountData = Omit<typeof schema.authoauthaccounts.$inferInsert, Metadata>

export type Authotp = typeof schema.authotps.$inferSelect
export type AuthotpData = Omit<typeof schema.authotps.$inferInsert, Metadata>

export type Authpasswordresettoken = typeof schema.authpasswordresettokens.$inferSelect
export type AuthpasswordresettokenData = Omit<typeof schema.authpasswordresettokens.$inferInsert, Metadata>

export type Auditlog = typeof schema.auditlogs.$inferSelect
export type AuditlogData = Omit<typeof schema.auditlogs.$inferInsert, Metadata>

export type Issue = typeof schema.issues.$inferSelect
export type IssueData = Omit<typeof schema.issues.$inferInsert, Metadata>

export type Note = typeof schema.notes.$inferSelect
export type NoteData = Omit<typeof schema.notes.$inferInsert, Metadata>

export type Notification = typeof schema.notifications.$inferSelect
export type NotificationData = Omit<typeof schema.notifications.$inferInsert, Metadata>

export type Task = typeof schema.tasks.$inferSelect
export type TaskData = Omit<typeof schema.tasks.$inferInsert, Metadata>

export type User = typeof schema.users.$inferSelect
export type UserData = Omit<typeof schema.users.$inferInsert, Metadata>

export type Usernotification = typeof schema.usernotifications.$inferSelect
export type UsernotificationData = Omit<typeof schema.usernotifications.$inferInsert, Metadata>

////////////////////////////////////////////////////////////////////////////////////////////////////

export const getDefaultAccountData = (data: Partial<Account['data']>) => null
export const getDefaultActivityData = (data: Partial<Activity['data']>) => null
export const getDefaultApplicationData = (data: Partial<Application['data']>) => null
export const getDefaultBankData = (data: Partial<Bank['data']>) => null
export const getDefaultBranchData = (data: Partial<Branch['data']>) => null
export const getDefaultCampaignData = (data: Partial<Campaign['data']>) => null
export const getDefaultCategoryData = (data: Partial<Category['data']>) => null
export const getDefaultContactData = (data: Partial<Contact['data']>) => null
export const getDefaultDealData = (data: Partial<Deal['data']>) => null
export const getDefaultFinancialaccountData = (data: Partial<Financialaccount['data']>) => null
export const getDefaultIndustryData = (data: Partial<Industry['data']>) => null
export const getDefaultInquiryData = (data: Partial<Inquiry['data']>) => null
export const getDefaultMessageData = (data: Partial<Message['data']>) => null
export const getDefaultProductionorderData = (data: Partial<Productionorder['data']>) => null
export const getDefaultProductionorderitemData = (data: Partial<Productionorderitem['data']>) => {
  return merge({
    approvedAt: null,
    approvedBy: null,
    items: [
      { resolved: false, label: 'Cantidad' },
      { resolved: false, label: 'Bandeo' },
      { resolved: false, label: 'Manchado' },
      { resolved: false, label: 'Traslape' },
      { resolved: false, label: 'Rebase' },
      { resolved: false, label: 'Corte' },
      { resolved: false, label: 'Acabados' },
    ],
  }, data)
}
export const getDefaultProductData = (data: Partial<Product['data']>) => null
export const getDefaultProductitemData = (data: Partial<Productitem['data']>) => null
export const getDefaultPricelistData = (data: Partial<Pricelist['data']>) => null
export const getDefaultPricelistitemData = (data: Partial<Pricelistitem['data']>) => null
export const getDefaultQuoteData = (data: Partial<Quote['data']>) => null
export const getDefaultQuoteitemData = (data: Partial<Quoteitem['data']>) => null
export const getDefaultSalesorderData = (data: Partial<Salesorder['data']>) => null
export const getDefaultSalesorderitemData = (data: Partial<Salesorderitem['data']>) => null
export const getDefaultServiceData = (data: Partial<Service['data']>) => null
export const getDefaultServiceitemData = (data: Partial<Serviceitem['data']>) => null
export const getDefaultServiceorderData = (data: Partial<Serviceorder['data']>) => {
  return merge({
    approvedAt: null,
    approvedBy: null,
    nps: 5,
  }, data)
}
export const getDefaultServiceorderitemData = (data: Partial<Serviceorderitem['data']>) => {
  return merge({
    approvalRequired: true,
    approvalStatus: 'pending',
    images: [],
    approvalText: null,
  }, data)
}
export const getDefaultTransactionData = (data: Partial<Transaction['data']>) => null
