import { AppProductitemsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type ProductitemitemsSlideoverProps = ComponentProps<typeof AppProductitemsSlideover>

export type ProductitemsUtils = ReturnType<typeof useProductitemsUtils>

export const useProductitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Productitem>('productitems')

  const slideoverOpenCreate = (props: AppProductitemsSlideoverProps) => {
    return new Promise<Productitem>((resolve, reject) => {
      const data = getDefaultProductitem(props.data)
      slideover.open(AppProductitemsSlideover, {
        title: 'Crear item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductitemsSlideoverProps) => {
    return new Promise<Productitem>((resolve, reject) => {
      const data = getDefaultProductitem(props.data)
      slideover.open(AppProductitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        disabledFields: ['productId'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    status: z.string(),
    name: z.string(),
    fiscalData: fiscalDataSchema,
    productId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Productitem, refresh: () => Promise<void>, utils: ProductitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('edit', 'productitems', row, refresh, utils),
      getDropdownItem('copyId', 'productitems', row, refresh, utils),
      getDropdownItem('archive', 'productitems', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'productitems', row, refresh, utils),
      getDropdownItem('files', 'productitems', row, refresh, utils),
    ],
    getDropdownItemGroup('status', 'productitems', row, refresh, utils),
    // resource actions:
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'productitems', row, refresh, utils),
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.product?.$inmutable,
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      getDropdownItem('delete', 'productitems', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useProductitemsUtils = ({ $products }: {
  $products?: Awaited<ReturnType<typeof useFetch<Product[]>>>
} = {}) => {
  const options = {
    ...optionsProductitems,
    fiscalData: {
      satUnitKey: optionsSAT.productUnitId,
    },
    productId: computed(() => $products?.data.value.map(toOption)),
  }

  const maps = {
    status: toMapByKey(optionsProductitems.status, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    product: {
      type: toMapByKey(optionsProducts.type, 'value'),
      status: toMapByKey(optionsProducts.status, 'value'),
    },
    productId: computed(() => toMapByKey($products?.data.value, 'id')),
    productsByType: computed(() => toMapByGroupKey($products?.data.value, 'type')),
  }

  return { options, maps }
}
